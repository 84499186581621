import type { LoaderFunctionArgs } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { Form, useLoaderData } from "@remix-run/react";

import { login } from "../../shopify.server";

import styles from "./styles.module.css";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);

  if (url.searchParams.get("shop")) {
    throw redirect(`/app?${url.searchParams.toString()}`);
  }

  return json({ showForm: Boolean(login) });
};

export default function App() {
  const { showForm } = useLoaderData<typeof loader>();

  return (
    <div className={styles.index}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Wall Art Viewer AR</h1>
        <p className={styles.text}>
          Automated Augmented Reality Experiences For Online Art Stores.
        </p>
        {showForm && (
          <Form className={styles.form} method="post" action="/auth/login">
            <label className={styles.label}>
              <span>Enter your shop domain</span>
              <input className={styles.input} type="text" name="shop" />
              <span>e.g: my-shop-domain.myshopify.com</span>
            </label>
            <button className={styles.button} type="submit">
              Install App
            </button>
          </Form>
        )}
        <ul className={styles.list}>
          <li>
            <strong>Perfectly Matched Art Frames</strong>
            <br />
            Ensure precise matching of art frame profiles, size, and mount board
            variants with our automated art templates. Achieve a perfect,
            life-sized representation of your art products.
          </li>
          <li>
            <strong>Effortless Store Integration</strong>
            <br />
            Experience a dynamic shift in online shopping with the ability to
            effortlessly convert your artworks into engaging augmented reality
            3D visuals, all at the click of a button.
          </li>
          <li>
            <strong>Streamlined Batch Processing</strong>
            <br />
            Efficiently manage multiple artworks, variants or your entire
            gallery collection with smart defaults built-in, saving you time and
            effort.
          </li>
          <li>
            <strong>Seamless Setup and Onboarding</strong>
            <br />
            We offer $99 setup and onboarding for a smooth integration,
            eliminating any guesswork and ensuring you are up to speed and can
            launch to market with confidence.
          </li>
        </ul>
      </div>
    </div>
  );
}
